<template>
  <div div class="addPerson__wrap" v-show="visible">
    <el-button style="margin:20px;" plain icon="el-icon-d-arrow-left" @click="toggle(false)">返回</el-button>
    <div class="main">
      <p class="title">{{dataInfo.title}}</p>
      <div class="time">
        <span style="margin-right:20px"><i class="el-icon-pie-chart" style="margin-right:5px"></i>发布时间：2022-03-06</span>
        <span><i class="el-icon-view" style="margin-right:5px"></i>阅读量：516</span>
      </div>

      <div class="content" style="line-height:30px;" v-html="this.dataInfo.content"></div>
    </div>
  </div>
</template>

<script>
import common from '@/util'
export default {
  name: 'ArticleDetail',
  data() {
    return {
      visible: false,
      dataInfo: {
        title: '',
        content: ''
      },
    }
  },
  methods: {
    // 获取详情
    getDetail(row) {
      this.isChange = true
      this.$http.get('/admin/article/detail', {params:{id: row.id} }).then(res => {
        if(res.code === 1) {
          common.deepClone(this.dataInfo, res.data)
          this.dataInfo.happen_at = this.$moment(res.data.created_at).format('YYYY-MM-DD')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    reset(done) {
      this.isChange = false
      this.$refs.elFormDom.resetFields()
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
  }
}
</script>
<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #fff;
  margin: 20px;
  z-index: 5 !important;
  overflow: scroll;
  .main {
    width: 80%;
    margin: 0 auto;
    text-align: center;

    .title {
      font-size: 28px;
      font-weight: bold;
    }
    .time {
      font-size: 18px;
      color: #8C8C8C;
    }
  }
  
}
</style>