<template>
  <div class="proposal__wrap">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params">
        <el-form-item label="文章标题:" prop="name">
          <el-input clearable v-model="table.params.name" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="文章类型:" prop="type">
          <el-select v-model="table.params.type" placeholder="请选择">
            <el-option-group
              v-for="group in optionsList"
              :key="group.id"
              :label="group.title">
              <el-option
                v-for="item in group.options"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="发布时间" prop="date">
          <el-date-picker
            v-model="table.params.date"
            :clearable="false"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button class="theneBtn" type="primary" icon="el-icon-search" @click="getTable">查询 </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button class="addBtn theneBtn" type="primary" icon="el-icon-plus" @click="showEdit">添加文章</el-button>
    </div>
    <!-- 表格 -->
    <div class="table__wrap">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="field"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showOverflowTooltip="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
        <template v-slot:action="{row}">
          <el-button type="text" icon="el-icon-view" @click="showDetail(row)">查看</el-button>
          <el-button type="text" icon="el-icon-edit" @click="showEdit(row)">编辑</el-button>
          <el-button class="delBtn" type="text" icon="el-icon-delete" @click="remove(row)">删除</el-button>
        </template>
      </VTable>
    </div>

    <edit ref="edit" @refresh="getTable" :optionsList="optionsList"></edit>
    <detail ref="detail"></detail>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue';
import Detail from './components/Detail.vue';
export default {
  name: 'ArticleList',
  components: {
    VTable,
    Edit,
    Detail,
  },
  data() {
    return {
      field: [
        { name: "id", label: "ID", width:"60", hidden: false },
        { name: "type_name", label: "文章类型", hidden: false },
        { name: "title", label: "文章标题", hidden: false },
        { name: "created_at", label: "发布时间", hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "200", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          type: '',
          name: '',
          date: [],
          create_min: '',
          create_max: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      optionsList: [],
    }
  },
  created() {
    this.getInfo();
    this.getTable();
  },
  methods: {
    getInfo() {
      this.$http.get('/admin/article/types',{params:{}}).then(res => {
        if(res.code === 1) {
          this.optionsList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getTable() {
      let _date = this.table.params.date;
      console.log(_date);
      this.table.params.create_min = _date.length > 0 ? _date[0] : '';
      this.table.params.create_max = _date.length > 0 ? _date[1] : '';
      this.$http.get('/admin/article/list', { params:this.table.params }).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 重置
    reset() {
      this.$refs.elFormDom.resetFields()
      this.getTable();
    },
    // pageSize 改变时会触发
    handleSizeChange(pageSize) {
      this.table.params.count = pageSize;
      this.table.params.page = 1;
      this.getTable();
    },
    handleCurrentChange(page) {
      this.table.params.page = page;
      this.getTable();
    },
    // 删除
    remove(row) {
      this.$confirm("是否删除此条记录？", "删除", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.delete(row.id);
            done()
          } else {
            done()
          }
        }
      }).catch(() => {})
    },
    delete(rowid) {
      this.$http.post('/admin/article/delete', { id: rowid }).then(res => {
        if(res.code === 1) {
          this.$message.success('操作成功！')
          this.getTable();
        } else {
          this.$message.error(res.data)
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
  .proposal__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    position: relative;

    .filter__wrap {
      padding: 10px;
      border-radius: 5px 5px 0 0;
    }

    .table__wrap {
      flex: 1;
      padding: 0;
      box-sizing: border-box;
    }
    .addBtn {
      margin-top: 20px;
    }
    .theneBtn {
      background-color: $theme !important;
      border-color: $theme !important;
    }
  }
</style>