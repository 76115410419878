<template>
  <div class="addPerson__wrap" v-show="visible">
    <el-button style="margin:20px;" plain icon="el-icon-d-arrow-left" @click="toggle(false)">返回</el-button>
    <div class="main">
      <el-form
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='110px'
        label-position='top'
        :model='form.data'
        :rules='form.rules'
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="文章类型:" prop="type">
              <el-select v-model="form.data.type" placeholder="请选择" >
                <el-option-group
                  v-for="group in optionsList"
                  :key="group.id"
                  :label="group.title">
                  <el-option
                    v-for="item in group.options"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id">
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="title" label="文章标题">
              <el-input placeholder="请输入文章标题" v-model="form.data.title" clearable></el-input>
            </el-form-item>
          </el-col>
          <template v-if="this.form.data.type === 4 || this.form.data.type === 5 || this.form.data.type === 8 || this.form.data.type === 11 || this.form.data.type === 12">
            <el-col :span="8">
              <el-form-item prop="happen_at" label="事件发生时间">
                <el-date-picker
                  v-model="form.data.happen_at"
                  type="date"
                  value-format="yyyy-MM-dd"
                  format="yyyy 年 MM 月 dd"
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </template>
          <el-col :span="24">
            <el-form-item prop="cover" label="文章封面" ref="elUpload">
              <el-upload
                class="avatar-uploader"
                action=""
                :headers="{ token: token }"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :http-request="httpRequest"
                >
                <img v-if="this.form.data.cover" :src="this.form.data.cover" class="avatar" style="object-fit: contain;">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="content" label="内容详情">
              <my-editor ref="editor" :editorTxt="this.form.data.content"></my-editor>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="end">
          <el-button @click='clean'>重置</el-button>
          <el-button class="theneBtn" type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
  import common from '@/util'
  import {getStorage} from '@/storage'
  import MyEditor from "@/components/MyEditor.vue";
  export default {
    name: 'NotifyEdit',
    props: ['optionsList'],
    components: {
      MyEditor,
    },
    data() {
      return {
        token: getStorage('token'),
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            type: '',
            title: '',
            cover: '',
            content: '',
            is_event: '',
            happen_at: '',
          },
          rules: {
            title: [{ required: true, message: '请输入标题', trigger: 'change' }],
            type: [{ required: true, message: '请选择类型', trigger: 'change' }],
            cover: [{ required: true, message: '请上传封面', trigger: 'change' }],
            happen_at: [{ required: true, message: '请选择时间', trigger: 'change' }],
            content: [{ required: true, message: '请输入内容详情', trigger: 'blur' }],
          }
        },
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true
        this.$http.get('/admin/article/detail', {params:{id: row.id} }).then(res => {
          if(res.code === 1) {
            common.deepClone(this.form.data, res.data)
            this.form.data.happen_at = this.$moment(res.data.created_at).format('YYYY-MM-DD')
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.form.data.content = this.$refs.editor.editorContent;
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
           this.form.loading = true;
            console.log(this.form.data)
           let apiUrl = !this.isChange ? '/admin/article/create' : '/admin/article/edit'
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('上传文件大小不能超过 5MB!');
        }
        return isLt5M;
      },
      httpRequest(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
          if(res.code === 1) {
            this.form.data.cover = res.data.all_path_url;
            this.$refs.elUpload.clearValidate()
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      // 重置
      clean() {
        this.$refs.elFormDom.resetFields()
        this.form.data.content = '';
      },
    }
  }
</script>
<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #fff;
  margin: 20px;
  z-index: 5 !important;
  overflow: scroll;
  .main {
    margin: 0 20px;
  }
  .theneBtn {
    background-color: $theme !important;
    border-color: $theme !important;
  }
}
</style>